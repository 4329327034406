import React, { createContext, useContext, useState, ReactNode, useEffect } from "react";
import AuthService from "../services/AuthService";
import { useSearchParams } from "react-router-dom";

type Token = {
  token: string | null;
  setStoredToken: (newToken: string) => void;
};

const TokenContext = createContext<Token | undefined>(undefined);

export const useToken = () => {
  const context = useContext(TokenContext);
  if (!context) {
    throw new Error("useToken must be used within a TokenProvider");
  }
  return context;
};

export const TokenProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [token, setToken] = useState<string | null>(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    token && AuthService.setToken(token);
  }, [token]);

  useEffect(() => {
    setToken(AuthService.getStoredToken());
  }, []);

  const setStoredToken = (newToken: string) => {
    setToken(newToken);
  };

  const tokenContextValue: Token = {
    token,
    setStoredToken,
  };

  useEffect(() => {
    if (searchParams.get("data")) {
      const params = searchParams.get("data");
      const data = JSON.parse(params);
      setStoredToken(data.token);
      localStorage.setItem("userId", data.userId);
      localStorage.setItem("clientIp", data.clientIp);
      localStorage.setItem("firstName", data.firstName);
      localStorage.setItem("lastName", data.lastName);
      localStorage.setItem("email", data.email);
    }
  }, []);

  return <TokenContext.Provider value={tokenContextValue}>{children}</TokenContext.Provider>;
};
