import moment from "moment";
import { IJobOffer } from "../types/cms-interfaces";
import { ITest } from "./tests";

export const getEarliestShiftDate = (jobOffer: IJobOffer) => {
  const earliestShift = jobOffer.shiftTimes?.sort((a, b) => moment(a.date).diff(moment(b.date)))[0];
  return earliestShift?.date ? moment(earliestShift.startTime) : moment(jobOffer.startDate).startOf("day");
};

export const getLatestShiftDate = (jobOffer: IJobOffer) => {
  const latestShift = jobOffer.shiftTimes?.sort((a, b) => moment(b.date).diff(moment(a.date)))[0];
  return latestShift?.date ? moment(latestShift.startTime) : moment(jobOffer.endDate).startOf("day");
};

export const tests = (): ITest[] => {
  // test data
  const testJobOffer: Partial<IJobOffer> = {
    startDate: "2021-09-01",
    endDate: "2021-09-02",
    shiftTimes: [
      {
        date: "2021-09-01",
        startTime: "2021-09-01T08:00:00Z",
        endTime: "2021-09-01T16:00:00Z",
      },
      {
        date: "2021-09-15",
        startTime: "2021-09-15T07:00:00Z",
        endTime: "2021-09-15T13:00:00Z",
      },
      {
        date: "2021-09-02",
        startTime: "2021-09-02T08:00:00Z",
        endTime: "2021-09-02T16:00:00Z",
      },
    ],
  };

  // write a function that tests getEarliestShiftDate function
  const testGetEarliestShiftDate = (): ITest => {
    const earliestShiftDate = getEarliestShiftDate(testJobOffer as IJobOffer);

    return {
      name: "getEarliestShiftDate",
      expected: moment("2021-09-01T08:00:00Z"),
      actual: earliestShiftDate,
      check: earliestShiftDate.isSame(moment("2021-09-01T08:00:00Z")),
    };
  };

  const testGetLatestShiftDate = () => {
    const latestShiftDate = getLatestShiftDate(testJobOffer as IJobOffer);

    return {
      name: "getLatestShiftDate",
      expected: moment("2021-09-15T07:00:00Z"),
      actual: latestShiftDate,
      check: latestShiftDate.isSame(moment("2021-09-15T07:00:00Z")),
    };
  };

  return [testGetEarliestShiftDate(), testGetLatestShiftDate()];
};
