export const routes = {
  LOGIN: "/api/users/login",
  REGISTER: "/api/users",
  WORKERS: "/api/workers",
  CENTERS: "/api/centers",
  USERS: "/api/users",
  ROLES: "/api/roles",
  JOB_OFFERS: "/api/job-offers",
  MEDIA: "/api/media",
  DOCUMENTS: "/api/documents",
  JOB_ROLES: "/api/job-roles",
  QUALIFICATIONS: "/api/qualifications",
  GLOBAL_FORMS: "/api/globals/forms",
};

export const days = [
  {
    value: "monday",
    label: "Monday",
  },
  {
    value: "tuesday",
    label: "Tuesday",
  },
  {
    value: "wednesday",
    label: "Wednesday",
  },
  {
    value: "thursday",
    label: "Thursday",
  },
  {
    value: "friday",
    label: "Friday",
  },
  {
    value: "saturday",
    label: "Saturday",
  },
  {
    value: "sunday",
    label: "Sunday",
  },
];

export const PrefferedWorkingArea = [
  { label: "Stockport", value: "stockport" },
  { label: "Reddish", value: "reddish" },
  { label: "Bramhall", value: "bramhall" },
  { label: "Cheadle", value: "cheadle" },
  { label: "Romiely", value: "romiely" },
];

export const WishToWorkWith = [
  {
    label: "Babies",
    value: "babies",
  },
  {
    label: "Toddlers",
    value: "toddlers",
  },
  {
    label: "Wobblers",
    value: "wobblers",
  },
  {
    label: "Pre-preschoolers",
    value: "preSchoolers",
  },
  {
    label: "Play-schoolers",
    value: "playSchoolers",
  },
];

export const WishToContactAbout = [
  {
    label: "Fulltime Day Care",
    value: "fulltimeDayCare",
  },
  {
    label: "Part Time Day Care",
    value: "parttimeDayCare",
  },
  {
    label: "Sessional Care",
    value: "seasonalCare",
  },
  {
    label: "Drop in care",
    value: "dropinCare",
  },
];

export const contactOptions = [
  {
    label: "e-mail",
    value: "email",
  },
  {
    label: "Phone Call",
    value: "phone",
  },
  {
    label: "SMS",
    value: "text",
  },
];

export const contactTime = [
  {
    label: "Morning",
    value: "morning",
  },
  {
    label: "Afternoon",
    value: "afternoon",
  },
  {
    label: "Evening",
    value: "evening",
  },
];

export const availabiltyText = {
  text1: "Below you can set your availability for the next rolling 4-week period. This is how you let us know when you are available for work. ",
  text2: "Childcare settings will be able to view your availability and offer you shifts on the days you indicate you are available. Please keep your availability up-to-date so that we can effectively match you with childcare settings with available shifts. ",
  text3:
    " You can use the “Open for work” toggle at the top of the page to pause/resume receiving shift offers",
  text4:"For each individual day you can toggle your availability on or off and you can indicate which types of shifts you are available for (e.g. full day, morning, afternoon, etc.). You can choose as many different types of shifts as you like.You can also add an optional note which will be visible to childcare settings before they offer you a shift.  "
}    

export const Religions = [
  {
    label: "Christian",
    value: "Christian",
  },
  {
    label: "Atheist",
    value: "Atheist",
  },
  {
    label: "Muslim",
    value: "Muslim",
  },
  {
    label: "Hindu",
    value: "Hindu",
  },
  {
    label: "Buddhism",
    value: "Buddhism",
  },
];

export const Ethnicity = [
  {
    label: "British",
    value: "British",
  },
  {
    label: "American",
    value: "American",
  },
];

export const PillsOptions = [
  { value: "morning", label: "Morning" },
  { value: "fullDay", label: "Full Day" },
  { value: "afternoon", label: "Afternoon" },
  { value: "lunchCover", label: "Lunch Cover" },
  { value: "wrapAroundCareAM", label: "Wrap Around Care (AM)" },
  { value: "wrapAroundCarePM", label: "Wrap Around Care (PM)" },
];

export const dashboardTitle = ["Identification ", "Experience & Qualifications", "References", "DBS Check"];

export const roles = ["Nursery Practitioner", "Senior Nursery Practitioner", "Teacher"];

export const jobOffersTabs = ["Received", "Accepted Shifts", "Declined & Withdrawn"];

export const confirmedJobsTabs = ["Active", "Finished", "Upcoming"];

export const jobTabs = ["Upcoming / Active", "Finished"];
