import React, { lazy, useEffect } from "react";
import { HamburgerMenuProvider } from "./context/HamburgerMenuContext";
import { TokenProvider } from "./context/TokenContext";
import { WorkerProvider } from "./context/WorkerContext";
import { tests } from "./utils/tests";

const AppRoutes = lazy(() => import("./components/Routes/Routes"));

tests();

const App = () => {
  //Clearing old app local storage
  useEffect(() => {
    // Add this in a central location in app1 (e.g., in a component or main script)
    window.addEventListener("message", (event) => {
      // Optionally check the origin of the message
      if (event.origin !== window.location.origin) {
        return;
      }

      if (event.data === "clearLocalStorage") {
        localStorage.clear();
      }
    });

    return () => {
      window.removeEventListener("message", () => {});
    };
  }, []);

  const url = `${process.env.REACT_APP_NEW_URL}/login`;

  return (
    <div>
      <TokenProvider>
        <WorkerProvider>
          <HamburgerMenuProvider>
            <AppRoutes />
          </HamburgerMenuProvider>
        </WorkerProvider>
      </TokenProvider>
    </div>
  );
};

export default App;
