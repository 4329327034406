import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";

interface HamburgerMenuContextType {
  isOpen: boolean;
  toggleMenu: () => void;
  closeMenu: () => void;
  browserWidth: number; // New property to store browser width
}

const HamburgerMenuContext = createContext<
  HamburgerMenuContextType | undefined
>(undefined);

export function useHamburgerMenu() {
  const context = useContext(HamburgerMenuContext);
  if (!context) {
    throw new Error(
      "useHamburgerMenu must be used within a HamburgerMenuProvider",
    );
  }
  return context;
}

interface HamburgerMenuProviderProps {
  children: ReactNode;
}

export function HamburgerMenuProvider({
  children,
}: HamburgerMenuProviderProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [browserWidth, setBrowserWidth] = useState(window.innerWidth); // Initialize with the current browser width

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  // Update browser width when the window is resized
  useEffect(() => {
    const handleResize = () => {
      setBrowserWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <HamburgerMenuContext.Provider
      value={{ isOpen, toggleMenu, closeMenu, browserWidth }}
    >
      {children}
    </HamburgerMenuContext.Provider>
  );
}
