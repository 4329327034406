import { createContext, useContext, ReactNode, useState } from "react";
import { IWorker } from "../types/cms-interfaces";

interface WorkerContextType {
  worker: IWorker;
  setWorker: (worker: IWorker) => void;
}

export const WorkerContext = createContext<WorkerContextType>(null as any);

export function WorkerProvider({ children }: { children: ReactNode }) {
  const [worker, setWorker] = useState<IWorker>({});

  return (
    <WorkerContext.Provider value={{ worker, setWorker }}>
      {children}
    </WorkerContext.Provider>
  );
}

export function useWorker() {
  const context = useContext(WorkerContext);
  if (context === undefined) {
    throw new Error("useWorker must be used within a WorkerProvider");
  }

  return context;
}
